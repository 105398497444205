import React from 'react';
import {
  Layout,
  SEO,
  H1,
  ServiceIndexContentSection,
  ContentRight,
  MoreHeading,
} from '.';
import { bodyRenderer } from '../helpers/bodyRenderer';
import { SidePanelItemSolidDivider } from './side-panel-item';

export const PageTemplate = props => {
  const { title, heading, body, rightSide, imgUrl } = props;

  return (
    <Layout>
      <SEO title={title} />
      <img src={imgUrl} alt={heading} />
      <H1>{heading}</H1>
      <ServiceIndexContentSection>
        <div>{body.map((b, idx) => bodyRenderer(b, `body-${idx}`))}</div>
        <ContentRight>
          <SidePanelItemSolidDivider />
          <MoreHeading>More</MoreHeading>
          {rightSide.map((b, idx) => bodyRenderer(b, `right-side-${idx}`))}
        </ContentRight>
      </ServiceIndexContentSection>
    </Layout>
  );
};

export default PageTemplate;
