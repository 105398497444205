import React from 'react';
import { PageTemplate } from './service-index-template';
import { bodyItemType } from '../helpers/bodyRenderer';
import {
  generateNonServiceSummaryItems,
  generateServiceSummaryItems,
} from '../helpers/summaryItems';
import { generateThumbnailData } from '../helpers/generateThumbnailData';

const ServicesPage = props => {
  const {
    pageContext: { thumbnails },
  } = props;

  const thumbnailData = generateThumbnailData(thumbnails);
  const servicesBody = generateServiceSummaryItems(
    thumbnailData,
    bodyItemType.SERVICE_PANEL
  );
  const rightSideBody = generateNonServiceSummaryItems(thumbnailData);

  return (
    <PageTemplate
      title="Services"
      heading="SERVICES"
      body={servicesBody}
      rightSide={rightSideBody}
      imgUrl={props.pageContext.imgUrl}
    />
  );
};

export default ServicesPage;
